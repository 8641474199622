body {
  font-family: "Merriweather", serif;
  margin: 0px;
  padding: 0px;
}
.buttonStyle {
  margin-top: 20px;
  padding: 10px;
  border: none;
  font-size: 18px;
  width: 100%;
}
.ck-editor__editable {
  min-height: 200px !important;
}
